<template>
  <div>
    <div class="block">
      <van-image round width="50px" height="50px" :src="headUrl" />
      <div>
        <span>{{ jurDescribe }}|</span>
        <span>{{ useraccount }}|</span>
        <span>{{ username }}|</span>
        <span>{{ openId }}|</span>
      </div>
      <van-button type="info" size="small" @click="logout">
        <template #icon>
          <van-icon name="replay" />
        </template>
        刷新
      </van-button>
    </div>
    <van-grid>
      <van-grid-item
        v-for="item in menuList"
        :key="item.id"
        :icon="item.icon"
        :text="item.text"
        :class="item && item.active == 1 ? 'navActive' : ''"
        @click="clickMenu(item)"
      />
    </van-grid>
  </div>
</template>

<script>
import request from '@/api/request.js';
export default {
  data() {
    return {
      jurlist: [], //权限集合
      menuList:[{id:'1',text:'转化率统计',active:'0',icon:require('@/assets/images/业绩统计.png')}],
      headUrl: '',
      nickName: '',
      username: '',
      useraccount: '',
      openId:'',
      jurDescribe: '',
    };
  },
  created() {
    this.headUrl = sessionStorage.getItem('imgUrl');
    this.nickName = sessionStorage.getItem('nickName');
    this.username = sessionStorage.getItem('username');
    this.useraccount = sessionStorage.getItem('useraccount');
    this.openId = sessionStorage.getItem('openId');
    let jurid = sessionStorage.getItem('userjurid');

    request({
      url: '/jur/getJurAll',
      method: 'get',
    })
      .then((res) => {
        this.jurlist = res.data.jurisdictions;
        let item = this.jurlist.filter((item) => item.jurId == jurid);
        this.jurDescribe = item[0].jurDescribe;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    logout() {
      this.$cookies.remove('token');
    },
    clickMenu(menu){
      console.log(menu.id);
        switch(menu.id){
          case '1':
              this.$router.push('/person/conversionRate')
            break;
          default:
            console.log('switch error');
        }
    }
  },
};
</script>
<style lang="less" scoped>
.block{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.navActive {
  background-color: gainsboro;
}
</style>
