import Vue from 'vue'
import Router from 'vue-router'
import indexDetail from '@/components/indexDetail.vue'
import bindUser from '@/views/bindUser.vue'
import contactIndex from '@/views/uploadIndex.vue'
import myIndex from '@/views/person/myIndex.vue'
import home from '@/views/home.vue'
import welcome from '@/views/welcome.vue'
import indexView from '@/views/indexView.vue'
import listIndex from '@/views/listIndex.vue'
import conversionRate from '@/views/person/conversionRate.vue'

Vue.use(Router)
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject)
//   }

// }


export default new Router({
  mode: 'history',
  routes: [{
    path: '/',
    //redirect: '/uploadIndex'
    component: home
    //children: [{}]
  }, {
    path: '/welcome',
    name: 'welcome',
    component: welcome
  }, {
    path: '/indexView/:id',
    name: 'indexView',
    component: indexView
  }, {
    path: '/listIndex',
    name: 'listIndex',
    component: listIndex
  }, {
    path: '/bindUser',
    name: 'bindUser',
    component: bindUser
  }, {
    path: '/uploadIndex',
    name: 'uploadIndex',
    component: contactIndex
  }, {
    path: '/indexDetail/:id',
    name: 'indexDetail',
    component: indexDetail
  }, {
    path: '/person/myIndex',
    name: 'myIndex',
    component: myIndex
  }, {
      path: '/person/conversionRate',
      name: 'conversionRate',
      component:conversionRate
  }]
}

)
