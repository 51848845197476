<template>
  <div class="main">
    <van-dropdown-menu>
      <van-dropdown-item v-model="currentNav" :options="navList" @change="navMenuChange" />
    </van-dropdown-menu>
    <el-calendar id="workSchedule" v-model="date">
      <template slot="dateCell" slot-scope="{ date, data }">
        <span>{{ date.getDate() }}</span>

        <span v-if="viewDate[data.day] && viewDate[data.day].num > 0" :style="viewDate[data.day].num < 10 ? 'padding:0px 5px;' : ''" class="date-tag">{{ viewDate[data.day].num }}</span>
      </template>
    </el-calendar>
    <van-notice-bar color="#00982D" background="rgba(50, 174, 87, 0.12)" left-icon="info">
      <van-tag size="large" type="success">微信通过数量:<span style="font-size:1.2rem">{{wxNum}}</span></van-tag>
      &nbsp;&nbsp;<van-tag size="large" type="danger">通过率:<span style="font-size:1.2rem">{{passRate}}%</span></span></van-tag></van-notice-bar>
    <div class="card" v-for="(item, index) in dayList" :key="index">
      <van-row>
        <van-col span="8"><van-image width="7rem" height="7rem" fit="cover" :src="item.printscreen" /></van-col>
        <van-col span="16">
          <van-row>
            <van-col span="24" style="display: flex;justify-content: center;align-items: center;">
              <van-icon name="manager-o" /><span style="color: blue">运营:</span><span>{{ item.operations }}</span>
              <i style="margin: 0 5px">|</i>
              <van-icon name="contact-o" /><span style="color: blue">客服:</span> <span v-if="item.customerService">{{ item.customerService }}</span
              ><span v-else>未指定</span>
              <van-button type="primary" size="small" round="true" @click="showWx=true;wxList = item.kfWxInfo">查看微信</van-button>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="12">
              <span>{{ item.navName }}</span>
            </van-col>
            <van-col span="12">
              <span>{{ item.contact }}</span>
            </van-col>
          </van-row>
          <van-row>
            <van-col span="24">
              <van-tag v-if="item.updateTime == null" plain type="danger">未更新线索</van-tag>
              <van-tag v-else plain type="success">{{ computeDate(item.dispathTime, item.updateTime) }}更新线索</van-tag>
              <i style="margin: 0 5px">|</i><span>响应速度：</span>
              <van-tag v-if="item.browseTime == null" plain type="danger">未浏览线索</van-tag>
              <van-tag v-else plain type="success">{{ computeDate(item.dispathTime, item.browseTime) }}浏览线索</van-tag>
            </van-col>
          </van-row>
          <van-row align="center" type="flex" justify="center">
            <van-col span="16">
              <van-tag v-if="item.wx == 1" type="success">已加微信</van-tag><van-tag v-else type="danger">未加微信</van-tag>
              <i style="margin: 0 5px">|</i>
              <van-tag v-if="item.ok == 1" type="success">已成交</van-tag><van-tag v-else type="danger">未成交</van-tag>
            </van-col>
            <van-col span="8">
              <van-button type="info" size="small" round="true" :to="'/indexDetail/' + item.id">查看详情</van-button>
            </van-col>
          </van-row>
        </van-col>
      </van-row>
    </div>
    <van-popup v-model="showWx" position="bottom">
      <ul style="padding:5px 10px;">
        <li v-for="item in wxList" :key="item.id" style="display: flex;justify-content: center;align-items: center;">
          <img :src="item.headimgurl" height="80px" style="border-radius: 50%;"/>
          <span style="margin-left:5px;">{{item.nickname}}</span>
        </li>
      </ul>
    </van-popup>
    <!-- <van-card v-for="(item, index) in dayList" :key="index" :desc="item.customerInfo" :class="item.wx == 0 || item.browseTime == null || item.updateTime == null ? 'red' : 'green'">
      <template #thumb>
        <div style="width: 88px; height: 88px">
          <van-imagewidth="10rem" height="10rem" fit="cover" :src="item.printscreen" />
        </div>
      </template>
      <template #title>
        <van-icon name="manager-o" /><span style="color: blue">运营:</span><span>{{ item.operations }}</span
        >&nbsp;&nbsp; <span>{{ item.navName }}</span
        >&nbsp;&nbsp;&nbsp;<span>{{ item.contact }}</span>
      </template>
      <template #tags>
        <van-icon name="contact-o" /><span style="color: blue">客服:</span><span v-if="item.customerService">{{ item.customerService }}</span
        ><span v-else>未指定</span> &nbsp;&nbsp;&nbsp;
        <van-tag v-if="item.browseTime == null" plain type="danger">未浏览线索</van-tag>
        <van-tag v-else plain type="success">{{ computeDate(item.dispathTime, item.browseTime) }}浏览线索</van-tag>&nbsp;&nbsp;
        <van-tag v-if="item.updateTime == null" plain type="danger">未更新线索</van-tag>
        <van-tag v-else plain type="success">{{ computeDate(item.dispathTime, item.updateTime) }}更新线索</van-tag>
      </template>
      <template #price> <van-tag v-if="item.wx == 1" type="success">已加微信</van-tag><van-tag v-else type="danger">未加微信</van-tag> &nbsp;&nbsp;&nbsp;<van-tag v-if="item.ok == 1" type="success">已成交</van-tag><van-tag v-else type="danger">未成交</van-tag> </template>
      <template #footer>
        <van-button type="info" size="small" :to="'/indexDetail/' + item.id">查看详情</van-button>
      </template>
    </van-card> -->
  </div>
</template>

<script>
import request from "@/api/request.js";
import moment from "moment";
export default {
  name: "welcome",
  data() {
    return {
      showWx:false,
      wxList:[],
      currentNav: "",
      currentDate: "",
      starDate: "",
      monthstr: "",
      viewDate: {},
      currentMonth: "",
      wxNum:0,
      passRate:0,
      date: new Date(),
      dayList: [],
      navList: [],
      navstr: "",
      id: "",
    };
  },
  watch: {
    date: {
      handler(newTime, oldTime) {
        // console.log(
        //   '触发了date修改事件/' +
        //     moment(newTime).startOf('months').format('YYYY-MM-DD')
        // );
        if (newTime == undefined) return;
        if (oldTime == undefined) {
          //console.log('触发了date修改事件');
          return;
        }

        const newSpan = newTime.getTime();
        const oldSpan = oldTime.getTime();
        if (newSpan !== oldSpan) {
          //this.navstr = sessionStorage.getItem('usermarid');
          this.starDate = moment(newTime).startOf("months").format("YYYY-MM-DD");
          this.monthstr = moment(newTime).startOf("months").format("YYYY-MM");
          if (this.currentMonth != this.monthstr) {
            this.currentMonth = this.monthstr;
            this.getStatisticsDayNum(this.starDate, this.monthstr, this.navstr);
          } else {
            this.currentDate = moment(newTime).format("YYYY-MM-DD");
            this.getIndexForDay(this.currentDate, this.navstr);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    //this.navList = JSON.parse(sessionStorage.getItem('navList'));
    this.navList.push({ value: "0", text: "全部市场" });
    this.currentNav = "0";
    JSON.parse(sessionStorage.getItem("navList")).forEach((item, index) => {
      this.navList.push({ value: item.navid, text: item.navname });
    });
    this.navstr = sessionStorage.getItem("usermarid");
    this.starDate = moment(new Date()).startOf("months").format("YYYY-MM-DD");
    this.currentMonth = this.monthstr = moment(new Date()).startOf("months").format("YYYY-MM");
    this.currentDate = moment(new Date()).format("YYYY-MM-DD");
    this.getStatisticsDayNum(this.starDate, this.monthstr, this.navstr);
    this.getIndexForDay(this.currentDate, this.navstr);
  },

  methods: {
    computeDate(starTime, endTime) {
      let diffTime = moment(endTime, "YYYY-MM-DD HH:mm:ss") - moment(starTime, "YYYY-MM-DD HH:mm:ss");
      let text = "";
      if (diffTime < 0) {
        diffTime = moment(starTime, "YYYY-MM-DD HH:mm:ss") - moment(endTime, "YYYY-MM-DD HH:mm:ss");
      }
      let hours = Math.floor(diffTime / (3600 * 1000));
      if (hours > 0) text += hours + "小时 ";
      let leave = diffTime % (3600 * 1000);
      let minutes = Math.floor(leave / (60 * 1000));
      if (minutes > 0) text += minutes + "分钟后";
      return text;
    },
    navMenuChange(value) {
      this.navstr = value == 0 ? sessionStorage.getItem("usermarid") : value;
      this.getStatisticsDayNum(this.starDate, this.monthstr, this.navstr);
      this.getIndexForDay(this.currentDate, this.navstr);
    },
    handleDetail(id) {
      this.id = id;
    },
    getIndexForDay(date, navList) {
      request({
        url: "/customerIndex/getIndexForDay",
        method: "get",
        params: {
          date,
          navList,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.dayList = res.data.list;
            this.wxNum = 0;
            this.dayList.forEach(item=>{
              if(item.wx=="1") this.wxNum++;
            })
            if(this.dayList.length>0){
              this.passRate = (this.wxNum/this.dayList.length*100).toFixed(2);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatisticsDayNum(starDate, monthstr, navList) {
      request({
        url: "/customerIndex/getStatisticsDayNum",
        method: "get",
        params: {
          starDate,
          monthstr,
          navList,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.viewDate = res.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/ .el-calendar-table .el-calendar-day {
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  height: auto;
}
/deep/.el-calendar__body{
  padding: 0px;
}
/deep/.el-dialog__body {
  padding: 0px 0px 0px;
}
.top-bar {
  background-color: #409eff;
}
.date-tag {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 11px;
  color: white;
  background-color: red;
}
.index-item {
  padding: 10px;
  border: 1px #dcdfe6 solid;
  border-radius: 4px;
  margin: 0 10px 10px 10px;
  display: flex;
  justify-content: space-between;
}
.index-item .info {
  display: flex;
  flex-direction: column;
}
.green {
  border: 1px green solid;
}
.red {
  border: 1px red solid;
}
.van-row {
  margin-top: 0.5rem;
}
.card {
  background-color: #f8f8f8;
  border-radius: 1rem;
}
</style>
