import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import api from "@/api/api.js";
import cookies from "vue-cookies";
import wx from "weixin-js-sdk";
import { Calendar } from "element-ui";
//import 'element-ui/lib/theme-chalk/index.css'
import "./assets/custom-vant.less";
import data from "./store/data";
import { Form, Field, Cell, CellGroup, Button, Picker, Popup, Uploader, Loading, Overlay, Grid, GridItem, Col, Row, Switch, Image, NavBar, Dialog, DropdownMenu, DropdownItem, Card, Tag, Icon, Notify, Checkbox, CheckboxGroup, Progress,NoticeBar } from "vant";
Vue.config.productionTip = false;

Vue.use(Form);
Vue.use(Field);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Uploader);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Switch);
Vue.use(Image);
Vue.use(NavBar);
Vue.use(Dialog);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Progress);
Vue.use(NoticeBar);
//Vue.use(ImagePreview);

Vue.use(cookies);
Vue.use(Vuex);
Vue.use(Calendar, { size: "medium " });
//Vue.component(Calendar.name, Calendar);
Vue.prototype.$wx = wx;
Vue.prototype.$Notify = Notify;

const store = new Vuex.Store({
  state: {
    isShow: false,
    showOverlay: false,
  },
  mutations: {
    // 展示loading的方法
    showLoading(state) {
      state.isShow = true
    },
    // 隐藏loading的方法
    hideLoading(state) {
      state.isShow = false
    },
    showOverlay (state) {
      state.showOverlay = true;
    },
    hideOverlay (state) {
      state.showOverlay = false;
    }
  },
  actions: {},
  modules: {}
})


router.beforeEach((to, _from, next) => {
  //页面是否登录
  const queryStr = window.location.search;
  const urlParams = new URLSearchParams(queryStr);
  let token = cookies.get("token");
  let targetUrl = "https://mp.xycm99.cn";
  //console.log('_form.name/'+_from.name+' to.name/' + to.name+' userid/'+sessionStorage.getItem('userid'));
  //console.log('token/' + token);
  //已经绑定登录过流程判断
  if (token != null) {
    if (sessionStorage.getItem("userid") == null || sessionStorage.getItem("userid") == "") {
      data
        .init()
        .then((res) => {
          console.log(res);
          next();
        })
        .catch((res) => {
          console.log(res);
        });
    } else {
      next();
    }
  } else if (token == null && urlParams.get("token") == null) {
    targetUrl = window.location.href;
    if (to.name == "bindUser") {
      let useraccount = to.params.useraccount;
      sessionStorage.setItem("useraccount", useraccount);
    }
    window.location.href = api.getBaseUrl + "/oauth/wxOauth?targetUrl=" + targetUrl;
  } else {
    //已经走完微信授权流程
    if (urlParams.get("token") == "") {
      //未绑定系统账号
      if (to.name != "bindUser") {
        //如果不是绑定账号页跳转到绑定页
        next("/bindUser");
      } else {
        next();
      }
    } else if (urlParams.get("token") != "") {
      token = urlParams.get("token");
      cookies.set("token", token, 60 * 60 * 24 * 7);
      data
        .init()
        .then((res) => {
          next();
        })
        .catch((res) => {
          console.log(res);
        });
    }
  }

  // window.__wxjs_is_wkwebview
  // true 时 为 IOS 设备
  // false时 为 安卓 设备
  if (window.__wxjs_is_wkwebview) {
    // IOS
    if (window.entryUrl == "" || window.entryUrl == undefined) {
      var url = `${to.fullPath}`;
      window.entryUrl = url; // 将后面的参数去除
    }
    console.log("ios/" + to.fullPath);
    //getWxAuth(to.fullPath,"ios")
  } else {
    // 安卓
    setTimeout(function () {
      //getWxAuth(to.fullPath,"android")
    }, 500);
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
